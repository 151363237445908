.Onboarding {
  height: 100vh;
  width: 100%;
  // background-color: #f6f6f6;
  background-color: var(--base-color-gray) !important;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .StepContainer {
    width: 60%;
    box-sizing: border-box;
    padding: 24px 20px;
    height: 100px;
  }
  .ContentContainer {
    height: 100%;
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    // padding: 20px 28px;
    box-sizing: border-box;
    // box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    .StepContainer {
      width: 100%;
    }

    .DetailContainer {
      height: 100%;
    }
  }

  .backIllustration {
    position: absolute;
    bottom: -46px;
    right: -57px;
    transform: rotate(331deg);
    opacity: 0.2;
  }

  .Greeting {
    height: 100%;
    .BusinessForm {
      display: flex;
      flex: 1;
      align-items: center;
      .UploadSection {
        width: 50%;
        // height: 60%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding-left: 24px;
      }
      .CustomDivider {
        height: 90%;
        width: 1px;
        background-color: rgb(128 128 128 / 15%);
      }
      .SingleCredentials {
        width: 50%;
        // height: 60%;
        padding-right: 24px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
    .TableContainer {
      flex: 1;
      .ant-float-btn-icon {
        width: 100% !important;

        div {
          width: 200px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .SolutionView {
      display: flex;
      align-items: center;
      margin-top: 66px;

      .SolutionCard {
        width: 220px;
        height: 180px;
        border-radius: 12px;
        background-color: var(--base-color-gray);
        padding: 20px;
        padding-top: 42px;
        box-sizing: border-box;
        position: relative;
        margin-right: 32px;
        box-sizing: border-box;
        cursor: pointer;
        transition: transform 0.4s;
        border: 2px solid var(--base-color-gray);

        .Title {
          background: white;
          padding: 2px 8px;
          border-radius: 15px;
          border: 1px solid gray;
        }

        .SolCheck {
          position: absolute;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background-color: var(--base-color-green);
          border: 2px solid white;
          top: -16px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          border: 2px solid var(--base-color-blue);
          transform: scale(1.1);
        }
      }
    }
  }
  //styles for connect for
  .ConnectFormContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .LeftContainer {
      width: 50%;
      padding: 12px;
      box-sizing: border-box;

      .ContactItem {
        margin: 24px 0;
        display: flex;
        align-items: center;
      }
    }

    .RightContainer {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .FormContainer {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 24px;
        width: 72%;
        border-radius: 11px;
        form {
          .FormRow {
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  .FinishScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 72px;
    }
  }

  .ant-float-btn-icon {
    width: 100% !important;
    div {
      width: 200px;
      display: flex;
      justify-content: center;
    }
  }
}

//ant modifiers

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--base-color-blue) !important;
  border-color: var(--base-color-blue) !important;

  svg {
    color: white;
  }
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--base-color-blue) !important;
}

.ant-steps-item-tail::after {
  width: 2px !important;
}
.ant-steps-item-icon {
  width: 24px !important;
  height: 24px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #2355d2 !important;
  border: 2px solid var(--base-color-blue) !important;

  box-shadow: 0 0 0 4px rgba(35, 85, 210, 0.4823529412) !important;
}
.ant-steps-finish-icon {
  font-size: 11px !important;
}

.ant-steps-item-title {
  font-size: 12px !important;
}

.ant-steps-item-container {
  display: flex !important;
  align-items: center !important;
}

.ant-steps-item-finish {
  .ant-steps-item-container {
    .ant-steps-item-title {
      color: var(--base-color-blue) !important;
      font-weight: 500;
    }
  }
}

.ant-steps-item-container {
  padding-left: 4px;
}
.ant-steps-item {
  padding: 5px 0;
}
