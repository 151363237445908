.MasterLayout {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: var(--base-color-gray);

  .NavigationContainer {
    height: 100%;
    background: white;
    z-index: 9999;
  }
  .ContentLayout {
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    margin-left: 0;
    // margin: 8px;
    // background-color: rgb(255 255 255 / 81%);
    background-color: white;
    // border-radius: 12px;
    margin-left: 0px;
    border: 1px solid #e1e4e9b0;

    .AppContent {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
  .CollapsedLayout {
    margin-left: 240px;
  }
}
