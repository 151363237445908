.PrimaryNav {
  width: 220px;
  height: 100%;
  transition: all 0.4s;
  .NavigationController {
    z-index: 9999;
    width: 100%;
    height: 100%;
    // border-right: 1px solid #d7e6f9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 22px 10px;
    box-sizing: border-box;
    justify-content: space-between;
    background-color: var(--base-color-gray) !important;
    position: relative;
    .ExpandAction {
      position: absolute;
      top: 8px;
      right: -15px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      background-color: white;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      align-items: center;
      border: 1px solid #8080804f;
      cursor: pointer;
      svg {
        fill: var(--base-color-blue);
      }
    }
    .TopNavigation {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .AppLogo {
        display: flex;
        justify-content: center;
        img {
          height: 32px;
        }
      }

      .MenuContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 42px;
        .MenuItem {
          .PrimaryMenuItem {
            width: 100%;
            border-radius: 6px;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            transition: all 0.4s;
            margin-bottom: 12px;
            box-sizing: border-box;
            padding: 6px 8px;
            .MenuLeft {
              display: flex;
              align-items: center;
              &.spaced {
                svg {
                  fill: var(--text-color-gray);
                  margin-right: 0 !important;
                }
              }
            }
            svg {
              fill: var(--text-color-gray);
              margin-right: 8px;
            }
            p {
              color: var(--text-color-gray);
            }

            &:hover {
              background-color: var(--base-color-blue-hover);
              svg {
                fill: var(--base-color-blue);
              }
            }
            &.active {
              // background-color: var(--base-color-blue-hover);

              background: #2575dc2e;
              // border: 1px solid #e1e4e9b0;
              svg {
                fill: var(--base-color-blue) !important ;
              }
              p {
                color: var(--base-color-blue);
                font-weight: 500 !important;
              }
            }
          }

          .SubMenu {
            padding-left: 12px;
            border-left: 1px solid #79797f66;
            margin-left: 14px;
            .MenuItem {
              width: 100%;
              border-radius: 6px;
              opacity: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              transition: all 0.4s;
              margin-bottom: 12px;
              box-sizing: border-box;
              padding: 6px 8px;
              .MenuLeft {
                display: flex;
                align-items: center;
              }
              svg {
                fill: var(--text-color-gray);
                margin-right: 8px;
              }
              p {
                color: var(--text-color-gray);
              }

              &:hover {
                background-color: var(--base-color-blue-hover);
                svg {
                  fill: var(--base-color-blue);
                }
              }
              &.active {
                // background-color: var(--base-color-blue-hover);

                background: #2575dc2e;
                // border: 1px solid #e1e4e9b0;
                svg {
                  fill: var(--base-color-blue);
                }
                p {
                  color: var(--base-color-blue);
                  font-weight: 500 !important;
                }
              }
            }
          }
        }
      }
    }
    .BottomNavigation {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .MenuItem {
        transition: all 0.4s;
        cursor: pointer;
        svg {
          fill: #4e535a;
        }
        &:hover {
          svg {
            fill: #4e535a;
          }
        }
        &:first-child {
          // margin-bottom: 1.5rem;
        }
        &:last-child {
          cursor: pointer;
        }
      }
    }
  }
}

.PrimaryNavSubMenu {
  .WorkspaceInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--base-color-blue-hover);
    padding: 12px 20px;

    .LeftContent {
      display: flex;
      align-items: center;
      .IconBox {
        border-radius: 3px;
        opacity: 1;
        background-color: rgba(121, 88, 159, 0.2);
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          object-fit: cover;
        }
      }
      .WorkspaceInfo {
        margin-left: 8px;
      }
    }
    .CollapseAction {
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      opacity: 1;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.4s;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
  }
  .MenuContainer {
    padding: 0 10px;
    margin-top: 20px;
    .MenuCategory {
      margin-bottom: 20px;
    }
  }
}
.UserAvatarInfoPrimaryNav {
  cursor: pointer;
  border-radius: 50%;
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .UserInfo {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      line-height: 1;
      margin-bottom: 2px;
      //   font-weight: 600;
    }
  }
}

// and design drawer modifier
.ant-drawer-body {
  padding: 0 !important;
}

ul {
  border-inline-end: none !important;
}
