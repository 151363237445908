:root {
  --absolute-black: #000;
  --absolute-black: #000;
  --text-black: #232333;
  --text-black-gray: #747487;
  --base-color-blue: #2575dc;
  --base-color-gray: #f4f6fa;
  --base-color-blue-hover: #ebf2ff;
  --text-black-dark: #172b4e;
  --text-black-subtitle: #2e384d;
  --base-color-green: #32ae88;
  --primary-purple: #79589f;
  --text-black-gray: #4e535a;
  --ag-alpine-active-color: #2345aa !important;
  --text-color-gray: #72747d;
  --text-color-black: #212121;

  --text-color-dark-gray: #79797f;
}

p {
  padding: 0px;
  margin: 0px;
}
