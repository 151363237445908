.Reconcilation {
  width: 100%;
  height: 100%;
  .ScreenContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .TabContainer {
      width: 100%;
      height: 100%;
      .TableContainer {
        flex: 1;
        .ag-root-wrapper {
          border: none !important;
        }
      }
      .TabContainer {
        height: 100%;
        margin-top: 12px;
      }
    }

    .TableContainer {
      flex: 1;
    }
  }
}

//ant-design modifiers

.ant-tabs {
  height: 100%;
  .ant-tabs-content {
    height: 100%;
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
}

.ant-tabs-tab-active {
  background-color: var(--base-color-blue) !important;
  p {
    color: white !important;
  }
  svg {
    fill: white !important;
  }
}

.ant-tabs-tab-with-remove {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
