.UnReconContainer {
  .custom-header-2ab .ag-header-cell-label {
    background-color: #f5f6f9 !important;
  }

  .ag-header-cell {
    background-color: #f5f6f9 !important;
  }

  .ag-header-group-cell-with-group {
    background-color: #f5f6f9 !important;
  }

  .ag-header-group-cell.ag-column-first {
    background-color: #f5f6f9 !important;
    color: white;
  }

  .ag-header-cell.ag-column-first {
    background-color: #f5f6f9 !important;
    color: white;
  }

  .ag-header-group-cell.ag-column-last {
    background-color: #f5f6f9 !important;
  }

  .ag-header-cell.ag-column-last {
    background-color: #f5f6f9 !important;
  }

  .ag-header-cell-text {
  }

  .ag-header-group-text {
  }

  .ag-icon-contracted {
    color: black !important;
  }
}

.ant-tabs-nav {
  padding: 0 14px !important;
}
