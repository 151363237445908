.PageHeader {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  border-bottom: 1px solid #e9ecf0;
  background-color: white !important;
  .HeaderLeft {
    display: flex;
    align-items: center;
    padding-left: 12px;

    .ItemContainer {
      // margin-right: 24px;
    }
    .externalAction {
      padding: 0 12px;
    }
  }
  .HeaderRight {
    display: flex;
    align-items: center;

    .ItemContainer {
      // margin-right: 24px;
    }
    .externalAction {
      padding: 0 12px;
    }
  }
}

.ant-dropdown-menu-title-content {
  p {
    overflow: hidden !important;
    // width: 50%;
  }
}

.ant-dropdown-menu {
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto; /* Add scrollbar if content overflows */
  overflow-x: hidden;
}
