.Reports {
  width: 100%;
  height: 100%;
  .ScreenContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .TableContainer {
      flex: 1;
      .ag-root-wrapper {
        border: none !important;
      }
    }
    .TabContainer {
      height: 100%;
      margin-top: 12px;
    }
  }
}
